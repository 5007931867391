<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'SEO Page'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'SEO Page Edit'"
      :title="'SEO Page Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
        <div v-if="langList.length" class="form-create__langs">
          <div
            v-for="lang in langList"
            :key="lang.id"
            @click="openLangModal(lang)"
            class="form-create__lang"
          >
            {{ lang.seoName }}
          </div>
        </div>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">URL</p>
          <div class="input-container">
            <InputText
              :onChange="setSeoName"
              :id="'url'"
              :label="'url'"
              :value="formDataToSend.url"
              :type="'text'"
              :placeholder="'URL'"
              :error-messages="validationErrors['url']"
              isBordered
            />
          </div>

          <p class="form-create__label">Title</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'title'"
              :label="'title'"
              :type="'text'"
              :placeholder="'Title'"
              :error-messages="validationErrors['title']"
              isBordered
            />
          </div>

          <p class="form-create__label">Description</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'description'"
              :label="'description'"
              :type="'text'"
              :placeholder="'Description'"
              :max-length="'255'"
              :error-messages="validationErrors['description']"
              isBordered
            />
          </div>

          <p class="form-create__label">H1</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'h1'"
              :label="'h1'"
              :type="'text'"
              :placeholder="'H1'"
              :error-messages="validationErrors['h1']"
              isBordered
            />
          </div>

          <p class="form-create__label">Annotation</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'annotation'"
              :label="'annotation'"
              :placeholder="'Annotation'"
              :error-messages="validationErrors['annotation']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Text</p>
          <div class="input-container">
            <TextEditor
              :set-value="formDataToSend"
              :id="'seo_text'"
              :placeholder="''"
              :error-messages="validationErrors['seo_text']"
            />
          </div>

          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'active'" :set-value="formDataToSend" />
          </div>

          <div class="input-container">
            <MainCheckbox
              :label="'Noindex ATTENTION!!!!!!'"
              :id="'noindex'"
              :set-value="formDataToSend"
            />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <MainModal v-if="langModal" :on-close="closeLangModal" :modal-center="true">
          <div class="lang-modal">
            <div class="lang-modal__title">Translations for {{ chosenLang.name }}</div>
            <form @submit.prevent="handleSubmitLang">
              <p class="form-create__label">Title</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'title'"
                  :label="'title'"
                  :type="'text'"
                  :placeholder="'Title'"
                  isBordered
                />
                <div
                  @click="handleTranslate('title', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>

              <p class="form-create__label">Description</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'description'"
                  :label="'description'"
                  :type="'text'"
                  :placeholder="'Description'"
                  :max-length="'255'"
                  isBordered
                />
                <div
                  @click="handleTranslate('description', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>

              <p class="form-create__label">H1</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'h1'"
                  :label="'h1'"
                  :type="'text'"
                  :placeholder="'H1'"
                  isBordered
                />
                <div @click="handleTranslate('h1', chosenLang.name)" class="form-create__translate">
                  Translate (Chat GPT)
                </div>
              </div>

              <p class="form-create__label">Annotation</p>
              <div class="input-container">
                <InputTextarea
                  :set-value="formDataToSendLang"
                  :id="'annotation'"
                  :label="'annotation'"
                  :placeholder="'Annotation'"
                  isBordered
                />
                <div
                  @click="handleTranslate('annotation', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>

              <p class="form-create__label">SEO Text</p>
              <div class="input-container">
                <TextEditor :set-value="formDataToSendLang" :id="'seo_text'" :placeholder="''" />
                <div
                  @click="handleTranslate('seo_text', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>
              <div class="form-create__btn">
                <MainButton :type="'submit'">Update</MainButton>
              </div>
            </form>
          </div>
        </MainModal>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import staticApi from "~/api/static";
import langApi from "~/api/lang";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";

export default {
  name: "SeoEdit",
  metaInfo: {
    title: "SEO Page Edit",
  },
  data() {
    return {
      formDataToSend: {
        h1: "",
        url: "",
        seo_text: "",
        active: false,
        annotation: "",
        title: "",
        description: "",
        noindex: false,
      },
      formDataToSendLang: {
        h1: "",
        seo_text: "",
        annotation: "",
        title: "",
        description: "",
        id: null,
      },
      langEdit: false,
      langModal: false,
      chosenLang: null,
      static: null,
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
    InputTextarea,
    MainModal,
    TextEditor,
  },
  mounted() {
    this.getItem();
    this.getLang();
  },
  computed: {
    ...mapState("statics", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("lang", ["lang", "langList"]),
    getFirstLink() {
      return ROUTE.SEO_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        seo_text: this.formDataToSend.seo_text,
        h1: this.formDataToSend.h1,
        url: this.formDataToSend.url,
        active: this.formDataToSend.active,
        noindex: this.formDataToSend.noindex,
        annotation: this.formDataToSend.annotation,
        title: this.formDataToSend.title,
        description: this.formDataToSend.description,
      };
      this.$store.commit("statics/setLoading", true);
      const url = `/${this.getId}`;
      try {
        staticApi
          .editSeo(url, data)
          .then((res) => {
            if (res.success) {
              this.$store.commit("statics/setError", {});
              this.$store.commit("statics/setValidationErrors", {});
              this.handleBackToList();
            }
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("statics/setError", data);
            if (data.errors) {
              this.$store.commit("statics/setValidationErrors", data.errors);
            }
          })
          .finally(() => {
            this.$store.commit("statics/setLoading", false);
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.SEO_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        staticApi.getItemSeo(url).then((res) => {
          this.$store.commit("statics/setError", {});
          this.$store.commit("statics/setValidationErrors", {});
          const data = res.data;
          this.static = res.data;
          this.formDataToSend.seo_text = data.seoText;
          this.formDataToSend.url = data.url;
          this.formDataToSend.active = data.active;
          this.formDataToSend.noindex = data.noindex;
          this.formDataToSend.annotation = data.annotation;
          this.formDataToSend.title = data.title;
          this.formDataToSend.h1 = data.h1;
          this.formDataToSend.description = data.description;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getLang() {
      let url = `?page=${this.page}`;
      this.$store.dispatch("lang/getLangList", url);
    },
    closeLangModal() {
      this.chosenLang = null;
      this.langModal = false;
      this.formDataToSendLang.h1 = "";
      this.formDataToSendLang.annotation = "";
      this.formDataToSendLang.title = "";
      this.formDataToSendLang.seo_text = "";
      this.formDataToSendLang.description = "";
      this.langEdit = false;
    },
    openLangModal(lang) {
      this.langModal = true;
      this.chosenLang = lang;

      const translate = this.static.translation;
      console.log(this.chosenLang.id);
      let translateStatic = translate.find((item) => item.langId === this.chosenLang.id);
      if (translateStatic) {
        this.formDataToSendLang.h1 = translateStatic.h1;
        this.formDataToSendLang.title = translateStatic.title;
        this.formDataToSendLang.description = translateStatic.description;
        this.formDataToSendLang.annotation = translateStatic.annotation;
        this.formDataToSendLang.seo_text = translateStatic.seoText;
        this.formDataToSendLang.id = translateStatic.id;
        this.langEdit = true;
      }
    },
    handleSubmitLang() {
      this.$store.commit("static/setLoading", true);
      const data = {
        h1: this.formDataToSendLang.h1,
        title: this.formDataToSendLang.title,
        annotation: this.formDataToSendLang.annotation,
        description: this.formDataToSendLang.description,
        seo_text: this.formDataToSendLang.seo_text,
        metadata_id: this.static.id,
        lang_id: this.chosenLang.id,
      };
      if (this.langEdit) {
        langApi
          .editLangSeo(data, `/${this.formDataToSendLang.id}`)
          .then(() => {
            this.$store.commit("statics/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("statics/setLoading", false);
            console.log(error);
          });
      } else {
        langApi
          .createLangSeo(data)
          .then(() => {
            this.$store.commit("statics/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("statics/setLoading", false);
            console.log(error);
          });
      }
    },
    handleTranslate(el, lang) {
      this.$store.commit("statics/setLoading", true);
      const data = {
        text: this.formDataToSend[el],
        language: lang,
      };
      langApi
        .getTranslation(data)
        .then((res) => {
          this.$store.commit("statics/setLoading", false);
          this.formDataToSendLang[el] = res.data.response;
        })
        .catch((error) => {
          this.$store.commit("statics/setLoading", false);
          console.log(error);
        });
    },
    setSeoName(data) {
      // const value = urlTransform(data);
      this.formDataToSend.url = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
